import Link from 'next/link';
import Image from 'next/image';

const Custom404 = () => {
  return (
    <section id="404-page" className="bg-black">
      <div className="content-wrapper flex flex-col items-center justify-center h-full text-ti-space-grey-text">
        <p className="text-center mt-10 text-2xl sm:text-3xl font-bold">Looks like this page might be out of range</p>
        <Image alt="404 page" src="/images/404/404.png" className="mx-auto" width={800} height={400} />
        <p className="text-center text-xl">Dont worry, we have a built in return to home feature</p>
        <div className="flex justify-center">
          <Link href="/">
            <a className="ti-button ripple w-60 text-lg mt-10">Return To Home</a>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Custom404;
